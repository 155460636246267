body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #29324b;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  display: flex;
  justify-content: center;
}

.button {
  background-color: transparent;
  border: none;
}
.button:hover {
  border-radius: 20%;
  background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
}

html,
body {
  overflow-x: hidden;
}
