.navbar {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  height: 50px;

  button {
    text-decoration: none;
  }

  a {
    width: fit-content;
    height: fit-content;
  }

  a:visited {
  }
}

.nav-spacing {
  padding-top: 50px; /* same spacing as navbar height */
}

.navbar::before {
  content: "hihihi";
}

.navbar::after {
  content: "byebyebye";
}
